<template>
  <div class="row">
    <div class="col-12">
      <div class="page-title-box">
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li class="breadcrumb-item"><a id="title2">反馈管理</a></li>
            <li class="breadcrumb-item active" id="title3">回复反馈</li>
          </ol>
        </div>
        <h4 class="page-title" id="title1">回复反馈</h4>
      </div>
    </div>
    <el-form :model="formData" label-width="20%" ref="formDataRef" :rules="addrules" align="left">
      <el-form-item label="用户ID">
        {{ formData.mid }}</el-form-item>
      <el-form-item label="反馈内容">
        {{ formData.fcontent }}</el-form-item>
      <el-form-item label="反馈时间">
        {{ formData.ftime }}</el-form-item>
      <el-form-item label="管理员回复" prop="freply">
        <el-input type="textarea" :rows="5" v-model="formData.freply" placeholder="管理员回复" size="small"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="save" :loading="btnLoading" icon="el-icon-upload">提 交</el-button>
        <el-button type="info" size="small" @click="goBack" icon="el-icon-back">返 回</el-button>
      </el-form-item>
    </el-form>


  </div>
</template>
<script>
import request, { base } from "../../../utils/http";

export default {
  name: 'FeedbackEdit',
  components: {

  },
  data() {
    return {
      id: '',
      isClear: false,
      uploadVisible: false,
      btnLoading: false, //保存按钮加载状态     
      formData: {}, //表单数据      
      addrules: {
        freply: [
          { required: true, message: "请输入管理员回复", trigger: "blur" },
        ],
      }, //表单验证规则     

    };
  },
  created() {
    this.id = this.$route.query.id;
    this.getDatas();
  },


  methods: {

    //获取列表数据
    getDatas() {
      let para = {
      };
      this.listLoading = true;
      let url = base + "/feedback/get?id=" + this.id;
      request.post(url, para).then((res) => {
        this.formData = JSON.parse(JSON.stringify(res.resdata));
        this.listLoading = false;


      });
    },

    // 添加
    save() {
      this.$refs["formDataRef"].validate((valid) => { //验证表单
        if (valid) {
          let url = base + "/feedback/update";
          this.btnLoading = true;

          request.post(url, this.formData).then((res) => { //发送请求         
            if (res.code == 200) {
              this.$message({
                message: "操作成功",
                type: "success",
                offset: 320,
              });
              this.$router.push({
                path: "/FeedbackManage",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "error",
                offset: 320,
              });
            }
            this.btnLoading = false;
          });
        }

      });
    },

    // 返回
    goBack() {
      this.$router.push({
        path: "/FeedbackManage",
      });
    },




  },
}

</script>
<style scoped></style>
 

